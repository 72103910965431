.item {
  padding: 0.2rem;
  display: flex;
  background-color: #f5f5f5;
  position: relative;
  transition: .3s;
}

.item:hover {
  box-shadow: 0 0.03rem 0.06rem -0.04rem #0000001f 1f, 0 0.06rem 0.16rem #00000014 14, 0 0.09rem 0.28rem 0.08rem #0000000d 0d;
}

.item .pic {
  flex-shrink: 0;
  cursor: pointer;
  margin-right: 0.4rem;
}

.item .pic img {
  width: 1.2rem;
  height: 1.8rem;
  object-fit: cover;
}

.item .txt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 1.56rem;
  flex: 1;
}

.item .txt h2 {
  font-size: 0.17rem;
  color: #333333;
  line-height: 1.556;
  cursor: pointer;
  margin-bottom: 0.15rem;
}

.item .txt h2:hover {
  color: #f6a75c !important;
}

.item .txt h2:hover span {
  color: #f6a75c !important;
}

.item .txt .des {
  font-size: 0.16rem;
  color: #555555;
  line-height: 1.5;
  margin-bottom: 0.2rem;
}

.item .txt .time {
  font-size: 0.16rem;
  color: #888888;
}

.item .handle {
  display: flex;
  align-items: center;
  padding: 0 0.3rem;
  margin: 0.3rem 0;
  border-left: 0.01rem solid #d5d5d5;
}

.item .handle .handle_item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #999;
  cursor: pointer;
  padding: 0.2rem;
}

.item .handle .handle_item:hover,
.item .handle .handle_item.active {
  color: #f6a75c;
}